export default {
  "certificates": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
    "firstaid": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Aid"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December 2023"])}
    },
    "vca": {
      "course": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AV-021 - Scaffolder"])},
          "validto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires 19-01-2034"])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IS-005 - Working with a self-propelled aerial platform"])},
          "validto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires 12-01-2029"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VCA"])}
    },
    "atsdcim": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATS DCIM Project builder"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May 2024"])}
    }
  },
  "education": {
    "1": {
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leercontract PC Technicus"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syntra"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December 2013 -June 2015"])}
    },
    "2": {
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor electronics-ICT"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thomas More"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February 2017 - January 2022"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])}
  },
  "general": {
    "readmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])}
  },
  "languages": {
    "en-US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "nl-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])}
  },
  "me": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floris Van der krieken"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])}
  },
  "nav": {
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
  },
  "other": {
    "1": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In my free time I do bug bounty hunting. My username on HackerOne is florisvdk."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug bounty hunting"])}
    },
    "2": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’m a RIPE NCC member."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIPE LIR"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "projects": {
    "as57225": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After having to change collocation providers several times, I decided to obtain my own IP addresses so that they will not change in the future."])},
      "modal": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The AS 57225 network aims to be a fully redundant autonomous system. This is currently still under construction. The network is already connected to the internet with 1 transit connection with a 2nd connection ordered from a 2nd provider. The BGP sessions are set up by 2 MikroTik CCR2004 routers. BGP routes are validated with RPKI and there are 2 DNS servers available."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS57225"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS57225"])}
    },
    "checkda": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda is a system for schools to simplify attendance and lunch administration."])},
      "modal": {
        "hardware": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The kiosks for the students use a Raspberry Pi with a touchscreen, a 3D printed housing and a self-made badge reader PCB."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware"])}
        },
        "software": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Checkda software consists of several parts. There is a student and parent portal that uses Keycloak for authentication. There are 2 different software versions for the kiosks, this one for the students and a version for use in the student secretariat. The management of all this is included in the Toekomstperspectief platform."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda"])}
    },
    "koelingmonitor": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IOT device and online platform for monitoring cooling installations."])},
      "modal": {
        "hardware": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The self-designed circuit board for this product uses an ESP32 as a microprocessor. The temperature is measured with a 1-wire temperature sensor. A lithium battery is built in to continue saving the data if the power goes out. There is an OLED screen to display the status of the device and temperature."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware"])}
        },
        "software": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The firmware for the devices is written in C. The firmware is compiled using GitLab CI and automatically downloaded by the devices. The web application for end users displays all historical data and has settings to send notifications via various channels."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koelingmonitor"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koelingmonitor"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "toekomstperspectief": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toekomstperspectief is a web-app for for schools to set goals and track progress."])},
      "modal": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Toekomstperspectief application uses a Vue.js frontend, PHP backend, redis cache and MariaDB database. The software is divided into containers with Docker. Treafik does TLS termination and CrowdSec is used as an extra protection."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toekomstperspectief"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toekomstperspectief"])}
    },
    "unifi-led": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After reverse engineering the UniFi LED API, I wrote an integration for Home Assistant."])},
      "modal": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Assistant page"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have written a Home Assistant integration for the now EOL UniFi LED product."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UniFi LED Home Assistant integration"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UniFi LED Home Assistant integration"])}
    }
  },
  "skills": {
    "tags": {
      "accesscontrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access control"])},
      "bgp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BGP"])},
      "bind9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIND 9"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
      "camerasecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera security"])},
      "checkmk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkmk"])},
      "crowdsec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CrowdSec"])},
      "docker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docker"])},
      "electronics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronics"])},
      "embedded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded hardware"])},
      "fiberfusionsplicing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiber fusion splicing"])},
      "freepbx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FreePBX"])},
      "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitLab"])},
      "ipv4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPv4"])},
      "ipv6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPv6"])},
      "js": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JavaScript"])},
      "keycloak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keycloak"])},
      "librenms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LibreNMS"])},
      "mailcow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailcow"])},
      "mariadb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MariaDB"])},
      "mikrotik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MikroTik"])},
      "modbus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modbus"])},
      "monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring"])},
      "netbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NetBox"])},
      "nfc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFC"])},
      "oauth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OAuth"])},
      "ospf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OSPF"])},
      "otdr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTDR"])},
      "pcb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printed circuit board design"])},
      "penetrationtesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penetration testing"])},
      "php": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP"])},
      "python": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Python"])},
      "redis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redis"])},
      "reverseengineering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverse engineering"])},
      "routinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routinator"])},
      "rpki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPKI"])},
      "traefik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traefik"])},
      "vue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue.js"])},
      "xcp-ng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XCP-ng"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge"])}
  },
  "workhistory": {
    "1": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support for consumers and companies remotely, on site at the customer and in the retail shop. Ranging from guidance on what to buy, setting up new workstations and setting up, maintaining and troubleshooting windows server environments"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technician"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksioma"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December 2013 - October 2015"])}
    },
    "2": {
      "description": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The design, installation and maintenance of networks for customers. Ranging from small to big installations. (restaurants, schools, campings, zoo)."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Hosting."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The programming of applications."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The design, installation, troubleshooting and maintenance of home automation and electrical installations. From lights to heating and cooling."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitization of company processes."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self employed"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February 2017 - Now"])}
    },
    "3": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ToDo"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datacenter service technician"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCL Data Centers"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 2023 - Now"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])}
  }
}